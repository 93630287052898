import React from "react"
import LayoutCategory from "../components/shared/layout/layout-category"
import {useIntl} from "gatsby-plugin-intl"

const Products = () => {
    const intl = useIntl()

    const data = {
        key: "products",
        title: intl.formatMessage({id: `seo.home.title`}),
        description: intl.formatMessage({id: `seo.home.desc`}),
        allProduct: true
    }
    return (
        <LayoutCategory data={data}/>
    )
}

export default Products
